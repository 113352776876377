.fc .fc-button.fc-prev-button.fc-prev-button,
.fc .fc-button.fc-next-button.fc-next-button {
  @apply h-7 w-7 p-0 text-foreground border-none rounded-md  bg-foreground/7;
}
.fc .fc-button-group .fc-button:hover.fc-button:hover {
  @apply bg-foreground/10;
}
.fc .fc-button-group .fc-button:active.fc-button:active {
  @apply bg-foreground/20;
  @apply text-foreground;
}
.fc .fc-button-group {
  @apply space-x-1;
}

.fc .fc-today-button.fc-today-button {
  border-radius: 5px;
  padding: 4px 12px;
  font-size: 14px;
  border: none;
  @apply !bg-foreground/5;
  @apply text-foreground;
  @apply font-medium;
}

.fc .fc-today-button.fc-today-button:active {
  @apply text-foreground;
  @apply !bg-foreground/10;
}

.fc .fc-today-button.fc-today-button:hover {
  @apply text-foreground;
  @apply !bg-foreground/10;
}

.fc .fc-button-primary:disabled:hover.fc-button-primary:disabled:hover {
  @apply !bg-foreground/10;
}

.fc .fc-timezone-button {
  background: transparent;
  border: none;
  @apply text-foreground;
}

.fc .fc-timezone-button:active {
  background: transparent;
  border: none;
  @apply text-foreground;
}

.fc .fc-timezone-button:hover {
  background: transparent;
  border: none;
  @apply text-foreground;
}

.fc .fc-loading-button {
  background: transparent;
  border: none;
  padding: 0;
}

.fc .fc-button-primary:disabled.fc-button-primary:disabled {
  background-color: #f7f7f7;
  color: #8a898e;
}


.fc .fc-button:focus.fc-button:focus {
  outline: 0;
  box-shadow: none;
}
.fc .fc-button:focus:active.fc-button:focus:active {
  outline: 0;
  box-shadow: none;
  background-color: #e6e6e6;
}
.fc .fc-timegrid-slot-label.fc-timegrid-slot-label {
  @apply border-none;
  @apply border-l-0;
  @apply relative;
}
.fc .fc-timegrid-slot-label-cushion.fc-timegrid-slot-label-cushion {
  margin-top: -18px;
  position: absolute;
  right: 0;
  top: 40%;
}
.fc .fc-col-header-cell.fc-col-header-cell {
  height: 40px;
  padding-top: 10px;
}
.fc .fc-event-title.fc-event-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  padding: 5px;
  color: #616161;
}
.fc .fc-event-time.fc-event-time {
  padding-left: 3px;
  color: #688c70;
}
.fc .fc-timegrid-slot-lane.fc-timegrid-slot-lane {
  @apply border-l-0;
}
.fc-daygrid-body-unbalanced.fc-daygrid-body-unbalanced {
  height: 34px;
}
.fc .fc-day-today.fc-day-today {
  background: inherit;
}
.fc .fc-header-toolbar.fc-header-toolbar {
  padding: 10px 24px 0px 24px;
  margin-bottom: 10px;
}
.fc .fc-event-time:after {
  content: none;
}
.fc .fc-toolbar-title.fc-toolbar-title {
  margin-top: 5px;
  font-size: 16px;
  @apply truncate;
}
.fc .fc-timegrid-divider.fc-timegrid-divider {
  display: none;
}
.fc .fc-non-business.fc-non-business,
.fc-daygrid-day-frame.fc-daygrid-day-frame,
.striped-background {
  background: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 4px,
    #f7f7f7 0,
    #f7f7f7 8px
  );
}
.fc .fc-scrollgrid.fc-scrollgrid {
  @apply border-foreground/5;
}
.fc-theme-standard td {
  @apply border-foreground/5;
}
.fc-day-disabled .fc-daygrid-day-frame {
  background: none;
}
.fc-day-disabled.fc-day-disabled {
  background-color: #f7f7f7;
}
.fc-inputs-container {
  height: calc(100% - 3.7rem);
}
@media (max-width: 600px) {
  .fc .fc-today-button.fc-today-button {
    display: none;
  }
}
@media (min-width: 600px) {
  .fc .fc-today-button.fc-today-button {
    display: inline-block;
  }
}

.configure-view .fc-timegrid-event.unconfigured {
  @apply bg-grey-950 border-none;
}

.configure-view .fc-timegrid-event.unconfigured.selected {
  @apply bg-grey-800;
}

.configure-view .fc-timegrid-event.configured {
  @apply bg-blue-950 border-none;
}

.configure-view .fc-timegrid-event.configured.selected {
  @apply bg-blue-900;
}

.configure-view .fc-timegrid-event.configured.filled {
  @apply bg-green-800 border-none;
}

.fc .configure-view td {
  @apply border-grey-950;
}

.configure-view .fc-event-main {
  @apply flex flex-col justify-center;
}

/* Booking View Styles */
.fc .booking-view {
  @apply bg-background;
}

.fc .booking-view .fc-timegrid-event {
  @apply bg-background bg-foreground/10;
  @apply border-none;
  @apply shadow-none;
}

.fc .booking-view .fc-timegrid-event-harness {
  @apply bg-background;
}

.fc .booking-view table {
  @apply border-none;
}

.fc .booking-view td,
.fc .booking-view tr,
.fc .booking-view table {
  @apply border-foreground/5;
}

.fc .booking-view table thead th {
  @apply border-l-0 border-t-0 border-b-0 border-r-foreground/5;
}

.fc .booking-view table thead th:last-child,
.fc .booking-view table thead th:first-child {
  @apply border-r-0;
}

.fc .booking-view table tbody {
  @apply border-none;
}

.fc .booking-view .fc-timegrid-col.fc-timegrid-axis {
  @apply border-none;
}

.fc .booking-view .fc-timegrid-col.fc-day.fc-day-sun {
  @apply border-none;
}

.fc .booking-view table td[role="presentation"] {
  @apply border-none;
}

.fc .booking-view .fc-timegrid-cols table tr td:last-child {
  @apply border-r-0 border-b-0;
}

.fc .booking-view .fc-timegrid-cols td.fc-day.fc-timegrid-col {
  @apply border-t-0 border-b-0;
}

.fc .booking-view .fc-timegrid-slot-lane.fc-timegrid-slot-lane {
  @apply border-solid;
}

.fc .booking-view .fc-timegrid-slot-lane.fc-timegrid-slot-lane:last-child {
  @apply border-r-0;
}

.fc .fc-toolbar-chunk {
  @apply flex items-center;
}

@media screen and (max-width: 800px) {
  .fc .fc-header-toolbar {
    @apply flex flex-col;
  }
}
